<template>
  <v-card>
    <v-card-title>
      請求取消依頼 詳細
      <v-chip
        small
        v-if="request.is_reapply"
        class="ml-2 mr-2 font-weight-bold"
        color="red"
        text-color="white"
      >
        再依頼
      </v-chip>
    </v-card-title>
    <v-divider class="my-0"></v-divider>
    <v-card-text
      ref="modal_content"
      class="pt-7 pb-3"
    >
      <v-row>
        <v-col class="py-0" cols="8" lg="3">
          <v-text-field
            dense
            readonly
            label="状態"
            v-model="request.status_label"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="4" lg="3">
          <v-text-field
            dense
            readonly
            label="案件ID"
            v-model="request.id"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="8" lg="6">
          <v-text-field
            dense
            readonly
            label="依頼日時"
            v-model="requestedAt"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="4" lg="3">
          <v-text-field
            v-if="request.is_reapply"
            dense
            readonly
            label="再依頼元ID"
            v-model="request.original_id"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="request.status > 1" class="mt-0 mb-2">
        <v-col>
          <v-alert v-if="request.status == 2" dense text type="warning" :icon="false" class="mb-0">
            現在確認作業を行っています。<br>
            今しばらくお待ちください。
          </v-alert>
          <v-alert v-if="request.status == 3" dense text type="error" :icon="false" class="mb-0">
            指定して頂いたお車は見つかりませんでした。<br>
            ナンバーが異なっているか、期間が異なっている可能性があります。<br>
            入力内容を変えて再度ご依頼ください。
          </v-alert>
          <v-alert v-if="request.status == 4" dense text type="error" :icon="false" class="mb-0">
            複数の候補が見つかり、お車を特定できませんでした。<br>
            画面下の「再依頼する」のボタンを押して再度ご依頼ください。<br>
            その際に４桁ナンバー以外の項目や、色や車種などの追加情報を入力して頂きますようお願いいたします。
          </v-alert>
          <v-alert v-if="request.status == 6" dense text type="error" :icon="false" class="mb-0">
            支払い請求対象ではありませんでした。<br>
            自動削除機能により未払い削除済みです。
          </v-alert>
          <div v-if="request.status == 5" >
            <v-alert dense text type="error" :icon="false">
              <div v-html="refuseReason"></div>
              <div class="py-1">
                <a :href="mailToUrl">smp-request-tools@pitdesign.jp</a><br>
              </div>
              <div>
                上記メールアドレスをクリックすると、メーラーが起動し、本文に依頼内容が自動で挿入されます。<br>
                依頼内容のコピーがうまくいかない場合は、お手数ですが、下記「依頼内容をクリックボードにコピー」ボタンをクリックすると依頼内容のコピーができますので、メールに貼り付けてお問い合わせをお願いいたします。<br>
                （当社の対応は祝日を除く月～金の9時から18時までとなります。）
              </div>
            </v-alert>
            <v-btn
              outlined
              color="info"
              @click="copyMailBody"
            >
              依頼内容をクリップボードにコピー
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="12" lg="6">
          <v-text-field
            dense
            readonly
            label="店舗名"
            v-model="request.store_name"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12" lg="6">
          <v-text-field
            dense
            readonly
            label="依頼者"
            v-model="request.user_name"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="7" lg="3">
          <v-text-field
            dense
            readonly
            label="車両情報"
            v-model="carInfo"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="5" lg="3">
          <v-text-field
            dense
            readonly
            label="依頼期間種別"
            v-model="request.target_period_label"
          ></v-text-field>
        </v-col>
        <v-col v-if="request.target_kind != 2" class="py-0" cols="12" lg="6">
          <v-text-field
            dense
            readonly
            :label="termLabel"
            v-model="requestTerm"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="4" lg="3">
          <v-text-field
            dense
            readonly
            label="受領金額"
            v-model="request.receipts"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="8" lg="9">
          <v-text-field
            dense
            readonly
            label="車種、色など"
            v-model="request.note"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12">
          <v-textarea
            dense
            readonly
            label="依頼理由"
            v-model="requestReason"
            no-resize
            rows="2"
          ></v-textarea>
        </v-col>
      </v-row>
      <template v-if="$store.getters.isAdminUser">
        <v-card-subtitle class="mt-7 pa-1 cyan darken-1 white--text font-weight-bold">管理用項目</v-card-subtitle>
        <div class="ma-2">
          WebUI:
          <a v-if="request.store_address" target="_blank" :href="'https://' + request.store_address">
            {{ request.store_address }}
          </a>
          <span v-else>
            未設定
            <v-btn class="ml-2" outlined x-small :to="'/request_client_stores/' + request.store_name">設定する</v-btn>
          </span>
        </div>
        <div  class="ma-2">
          削除権限: {{ deletionHolderLabel(request.deletion_right_holder) }}
          <v-btn v-if="request.deletion_right_holder == 0" class="ml-2" outlined x-small :to="'/request_client_stores/' + request.store_name">設定する</v-btn>
        </div>
        <v-textarea
          class="mb-2"
          outlined
          dense
          v-model="editedOperationNote"
          rows="2"
          label="管理用フリーテキスト"
          hide-details
          @input="onchange"
        ></v-textarea>
        <ValidationObserver v-slot="{ invalid }">
          <v-row>
            <v-col cols="12" lg="8" class="pb-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="更新後の状態"
                rules="required"
              >
                <v-select
                  outlined
                  color="primary"
                  dense
                  label="状態の変更"
                  item-text="label"
                  item-value="id"
                  :items="statusItems"
                  v-model="selectedstatusId"
                  :error-messages="errors"
                  @change="changeStatusItem"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col v-if="selectedstatusId == 5" cols="12" lg="8" class="py-0">
              <ValidationProvider
                v-slot="{ errors }"
                name="取消対象外の理由"
                rules="required"
              >
                <v-select
                  outlined
                  color="primary"
                  dense
                  label="取消対象外の理由"
                  item-text="label"
                  item-value="id"
                  :items="refuseReasonItems"
                  :error-messages="errors"
                  v-model="selectedDeleteRequestRefuseReasonId"
                  @change="onchange"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <template v-if="selectedstatusId == 5 && selectedDeleteRequestRefuseReasonId != ''">
              <v-col cols="12" class="mt-0 py-0">
                <v-alert dense text type="error" :icon="false" class="mb-0">
                  <div v-html="refuseReasonItems.find(item => item.id === selectedDeleteRequestRefuseReasonId).reason.replace(/\n/g,'<br>')"></div>
                  <div class="py-1">
                    <a :href="mailToUrl">smp-request-tools@pitdesign.jp</a><br>
                  </div>
                  <div>
                    上記メールアドレスをクリックすると、メーラーが起動し、本文に依頼内容が自動で挿入されます。<br>
                    依頼内容のコピーがうまくいかない場合は、お手数ですが、下記「依頼内容をクリックボードにコピー」ボタンをクリックすると依頼内容のコピーができますので、メールに貼り付けてお問い合わせをお願いいたします。<br>
                    （当社の対応は祝日を除く月～金の9時から18時までとなります。）
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" lg="8">
                <small>上記内容が更新後画面上部に表示されます。</small>
              </v-col>
            </template>
            <v-col cols="12" lg="4">
              <v-btn small outlined color="primary" @click="updateStatus" :disabled="(!isChanged || invalid)">更新する</v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </template>
    </v-card-text>
    <v-divider class="my-0"></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!$store.getters.isAdminUser && (request.status == 3 || request.status == 4)"
        small
        outlined
        color="warning"
        @click="reapply"
      >
        再依頼する
      </v-btn>
      <v-btn small outlined color="blue-grey" @click="close">閉じる</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment"
import qs from 'qs'

export default {
  props: ["request"],
  data: () => ({
    mailToUrl: '',
    statusItems: [
      { id: 1, label: "請求取消済み"},
      { id: 2, label: "確認中"},
      { id: 3, label: "該当無し"},
      { id: 4, label: "特定できず"},
      { id: 5, label: "請求取消対象外"},
      { id: 6, label: "支払い請求無し"},
    ],
    deletionHolderLabels: [
      { id: 0, label: '未設定' },
      { id: 1, label: '非権利者' },
      { id: 2, label: '権利者' },
    ],
    selectedstatusId: '',
    refuseReasonItems: [],
    selectedDeleteRequestRefuseReasonId: '',
    editedOperationNote: '',
    isChanged: false,
  }),
  watch: {
    request () {
      this.setMailToUrl()
      this.setParams()
    },
  },
  computed: {
    carInfo () {
      let info = ''
      if (this.request.car_place) { info += `${this.request.car_place} ` }
      if (this.request.car_class) { info += `${this.request.car_class} ` }
      if (this.request.car_kana) { info += `${this.request.car_kana} ` }
      info += this.request.car_number
      return info
    },
    requestTerm () {
      if (!this.request.entrant_time && !this.request.started_at) {
        return "-"
      } else if (this.request.target_kind == 0) {
        return moment(this.request.entrant_time).format( "yyyy/MM/DD" )
      } else {
        return `${moment(this.request.started_at).format( "yyyy/MM/DD" )} 〜 ${moment(this.request.ended_at).format( "yyyy/MM/DD" )}`
      }
    },
    termLabel () {
      return this.request.target_kind == 0 ? '請求取消対象日' : '請求取消対象期間'
    },
    requestReason () {
      if (!this.request.delete_request_reason_id) {
        return "-"
      } else {
        return this.request.request_reason_text ? this.request.reason + "：" + this.request.request_reason_text : this.request.reason
      }
    },
    requestedAt () {
      return moment(this.request.created_at).format( "yyyy/MM/DD HH:mm:ss" )
    },
    refuseReason () {
      return this.request.refuse_reason.replace(/\n/g,'<br>')
    },
    mailBody () {
      return `お問い合わせ内容をご入力ください。




        //// 以下変更せずにそのまま送信してください。 ////
        案件ID: ${this.request.id}
        店舗名: ${this.request.store_name}
        車両情報: ${this.carInfo}
        依頼期間種別: ${this.request.target_period_label}
        ${this.request.target_kind != 2 ? "請求取消対象期間: " + this.requestTerm : ''}
        受領金額: ${this.request.receipts}
        車種、色など: ${this.request.note}
        依頼者: ${this.request.user_name}
        依頼日時: ${this.requestedAt}
        状態: ${this.request.status_label}
        ////////////////////////////////////////////
        `.replace(/^ {8}/gm, '')
    },
    deletionHolderLabel () {
      return (value) => {
        return this.deletionHolderLabels.find(label => label.id === value).label
      }
    },
  },
  methods: {
    close () {
      this.$emit('close')
      this.$refs.modal_content.scrollTop = 0
      this.isChanged = false
    },
    reapply () {
      this.$router.push({path: `/reapply-delete-request/${this.request.id}`})
    },
    setMailToUrl () {
      const url = new URL(`mailto:smp-request-tools@pitdesign.jp`)
      url.searchParams.set('subject', '請求取消対象外について問合せ')
      url.searchParams.set('body', this.mailBody)
      this.mailToUrl = url.toString()
    },
    copyMailBody () {
      navigator.clipboard.writeText(this.mailBody)
      .then(() => {
        this.$buefy.toast.open({
          message: `コピーしました`,
          type: "is-success",
        })
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: `コピーに失敗しました`,
          type: "is-danger",
        })
      })
    },
    getRefuseReasons () {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_refuse_reasons"
      this.axios
        .get(url)
        .then(response => {
          this.refuseReasonItems = response.data
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    changeStatusItem () {
      if (this.selectedstatusId != 5) {
        this.selectedDeleteRequestRefuseReasonId = ''
      }
      this.onchange()
    },
    onchange () {
      this.isChanged = true
    },
    setParams () {
      this.selectedstatusId = this.request.status
      this.selectedDeleteRequestRefuseReasonId = this.request.delete_request_refuse_reason_id ? this.request.delete_request_refuse_reason_id : ''
      this.editedOperationNote = this.request.operation_note
    },
    async updateStatus () {
      const url = process.env.VUE_APP_BASE_URL + "delete_request_histories/" + this.request.id
      const data = {
        status_id: this.selectedstatusId,
        delete_request_refuse_reason_id: this.selectedDeleteRequestRefuseReasonId,
        operation_note: this.editedOperationNote,
      }
      const options = {
        url: url,
        method: 'PUT',
        data: qs.stringify(data),
      }
      await this.axios(options)
        .then(() => {
          this.$emit("updateRequests")
          this.close()
          this.$buefy.toast.open({
            message: `依頼を更新しました`,
            type: "is-info",
          })
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            console.log(err)
            this.$buefy.toast.open({
              message: `依頼更新に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
  },
  mounted() {
    this.setMailToUrl()
    this.getRefuseReasons()
    this.setParams()
  }
}
</script>

<style scoped>
</style>