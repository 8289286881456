<template>
  <v-container>
    <v-card tile>
      <v-toolbar flat>
        <v-toolbar-title>
          請求取消依頼 申請状況一覧
        </v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="font-weight-black"
          :color="drawer ? 'grey' : 'info'"
          @click.stop="drawer = !drawer"
        >
          <v-icon>
            {{ drawer ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down' }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-container v-if="drawer">
        <v-row>
          <v-col cols="12" lg="4" class="py-0">
            <v-autocomplete
              :outlined="$store.getters.isAdminUser"
              dense
              :clearable="$store.getters.isAdminUser"
              label="店舗名"
              :items="request_client_stores"
              item-text="name"
              item-value="id"
              v-model="selected.requestClientStoreId"
              :disabled="!$store.getters.isAdminUser"
              @change="getFilteredDeleteRequestHistories"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" lg="4" class="py-0">
            <v-select
              outlined
              dense
              v-model="selected.status"
              :items="statusItems"
              item-text="label"
              item-value="id"
              label="状態"
              @input="getFilteredDeleteRequestHistories"
            ></v-select>
          </v-col>
          <v-col cols="6" lg="4" class="py-0">
            <v-text-field
              outlined
              dense
              clearable
              v-model="selected.number"
              append-icon="mdi-magnify"
              label="4桁ナンバー"
              @input="getFilteredDeleteRequestHistories"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-text class="pt-0">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left hidden-md-and-down">
                  案件ID
                </th>
                <th class="text-left">
                  依頼日時
                </th>
                <th class="text-left hidden-md-and-down">
                  店舗名
                </th>
                <th class="text-left hidden-md-and-down">
                  依頼者
                </th>
                <th class="text-left">
                  車両番号
                </th>
                <th class="text-left hidden-md-and-down">
                  依頼期間
                </th>
                <th class="text-left hidden-md-and-down">
                  状態
                </th>
              </tr>
            </thead>
            <tbody v-if="!isLoading && requests.length != 0">
              <v-hover
                v-for="request in requests"
                :key="request.id"
                v-slot="{ hover }"
              >
                <tr :class="getClass(request, hover)" @click.stop="showDialog(request)">
                  <td class="text-left hidden-md-and-down">{{ request.id }}</td>
                  <td class="text-left">{{ formatDate(request.created_at) }}</td>
                  <td class="text-left hidden-md-and-down">{{ request.store_name }}</td>
                  <td class="text-left hidden-md-and-down">{{ request.user_name }}</td>
                  <td>{{ `${request.car_place} ${request.car_class} ${request.car_kana} ${request.car_number}`  }}</td>
                  <td class="text-left hidden-md-and-down">{{ request.target_period_label }}</td>
                  <td class="text-left hidden-md-and-down">{{ request.status_label }}</td>
                </tr>
              </v-hover>
            </tbody>
          </template>
        </v-simple-table>
        <v-container v-if="!isLoading && requests.length == 0" class="text-center my-10">
            該当する依頼がありません
        </v-container>
        <v-container v-if="isLoading" class="text-center my-10">
          <v-progress-circular
            :size="70"
            :width="7"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </v-container>
        <v-divider class="mt-0 mb-1"></v-divider>
      </v-card-text>
      <v-row v-if="requests.length != 0" justify="center">
        <v-col cols="10">
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="7"
            @input="getDeleteRequestHistories"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn small outlined color="blue-grey" to="/">戻る</v-btn>
      </v-toolbar>
    </v-card>
    <v-dialog
      scrollable
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <RequestDetail
        :request="showedRequest"
        @close="close"
        @updateRequests="getDeleteRequestHistories"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment"
import RequestDetail from './RequestDetail'

export default {
  components: {
    RequestDetail,
  },
  data: () => ({
    isLoading: false,
    drawer: true,
    request_client_stores: [],
    targetPeriodLabels: [
      "1日分",
      "期間指定",
      "全期間",
    ],
    statusItems: [
      { id: '', label: "全て" },
      { id: 0, label: "請求取消待ち", bg: '' },
      { id: 1, label: "請求取消済み", bg: 'light-green' },
      { id: 2, label: "確認中", bg: 'yellow' },
      { id: 3, label: "該当無し", bg: 'brown' },
      { id: 4, label: "特定できず", bg: 'purple' },
      { id: 5, label: "請求取消対象外", bg: 'deep-orange' },      
      { id: 6, label: "支払い請求無し", bg: 'blue-grey' }
    ],
    selected: {
      requestClientStoreId: null,
      number: null,
      status: '',
    },
    requests: [],
    dialog: false,
    requestId: '',
    showedRequest: '',
    currentPage: 1,
    requestsPerPage: 10,
    totalPages: null,
  }),
  methods: {
    getFilteredDeleteRequestHistories () {
      this.currentPage = 1
      this.getDeleteRequestHistories()
    },
    async getDeleteRequestHistories () {
      this.isLoading = true
      let url =''
      if (this.$route.query.request_id) {
        url = `${process.env.VUE_APP_BASE_URL}delete_request_histories?page=${this.currentPage}&per=${this.requestsPerPage}&request_client_store_id=${this.selected.requestClientStoreId}&request_id=${this.requestId}`
      } else {
        url = `${process.env.VUE_APP_BASE_URL}delete_request_histories?page=${this.currentPage}&per=${this.requestsPerPage}&status=${this.selected.status}&request_client_store_id=${this.selected.requestClientStoreId}&number=${this.selected.number}`
      }
      await this.axios
        .get(url)
        .then(response => {
          this.requests = response.data.requests
          this.requests.map(request => {
            request.receipts = request.receipts || 'なし'
            request.note = request.note || 'なし'
            request.target_period_label = this.targetPeriodLabels[request.target_kind]
            request.status_label = this.statusItems.find(item => item.id === request.status).label
          })
          this.totalPages = response.data.total_pages
          // 案件IDの指定があればモーダルを表示する
          if (this.requests.length == 1 && this.$route.query.request_id && (this.$store.getters.isAdminUser || this.$store.getters.userRequestClientStoreId == this.requests[0].request_client_store_id)) {
            this.showedRequest = this.requests[0]
            this.dialog = true
          }
        })
        .catch(err => {
          if (err.response && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
      this.isLoading = false
    },
    getRequestClientStores () {
      const url = process.env.VUE_APP_BASE_URL + "request_client_stores"
      this.axios
        .get(url)
        .then(response => {
          this.request_client_stores = response.data
        })
        .catch(err => {
          if (this.$route.path.match('/login')) {
            return
          } else if (err.rsponse && err.response.status == 401) {
            this.$buefy.toast.open({
              message: `認証に失敗しました。ログインし直してください。`,
              type: "is-danger",
            })
            this.$store.commit('clearUser')
            this.$router.push({path: "/login"})
          } else {
            this.$buefy.toast.open({
              message: `店舗情報の取得に失敗しました`,
              type: "is-danger",
            })
          }
        })
    },
    formatDate (date) {
      if( date ) return moment(date).format( "YY/MM/DD HH:mm:ss" )
    },
    getClass (request, hover) {
      let className = this.statusItems.find(item => item.id == request.status).bg
      if (!hover) {
        className += ' lighten-3'
      }
      return className
    },
    showDialog (request) {
      this.$router.push({
        query: {
          request_client_store_id: this.selected.requestClientStoreId,
          request_id: request.id,
        }
      })
      this.showedRequest = request
      this.dialog = true
    },
    close () {
      this.$router.push({query: null })
      this.getDeleteRequestHistories()
      this.dialog = false
    }
  },
  mounted() {
    if (!this.$store.getters.isAdminUser) {
      this.selected.requestClientStoreId = this.$store.getters.userRequestClientStoreId
    }
    // request_idの指定があるかどうかを確認
    if (this.$route.query.request_id) {
      this.requestId = this.$route.query.request_id
    }
    this.getDeleteRequestHistories()
    this.getRequestClientStores()
  },
}
</script>

<style scoped>
</style>
